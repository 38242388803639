<template>
  <BaseCard>
    <header class="sm:flex items-center justify-between">
      <div class="flex items-center">
        <span
          class="flex flex-col justify-center items-center h-10 w-10 rounded-full mr-4 bg-secondary text-white"
        >
          <Icon
            icon="Team"
            width="24px"
            height="24px"
            view-box="0 0 80.13 80.13"
          >
            <Team />
          </Icon>
        </span>
        <h2 class="text-lg">
          Guide
        </h2>
      </div>
    </header>

    <div class="prose mt-4">
      <h3>Authentication</h3>
      <p>
        All requests use HTTP Basic auth, which is a username and password, joined by a colon, encoded using Base64, in a Authorization header:
      </p>
      <pre>Authorization: Basic &lt;credentials&gt;</pre>
      <p>
        Use the token key as the username, and the token secret as the password:
      </p>
      <pre>tokenKey:tokenSecret</pre>
      <p>
        Then encode using Base64, and set as the header:
      </p>
      <pre>Authorization: Basic QWxhZGRpbjpvcGVuIHNlc2FtZQ==</pre>

      <h4>Guzzle</h4>
      <p>
        See
        <a
          href="https://docs.guzzlephp.org/en/latest/request-options.html#auth"
          target="_blank"
        >auth documentation</a>
        for implementing using Guzzle.
      </p>
      <pre>$client->request('GET', '/get', [
  'auth' => ['tokenKey', 'tokenSecret'],
]);</pre>

      <h4>HTTParty</h4>
      <p>
        See
        <a
          href="https://www.rubydoc.info/github/jnunemaker/httparty/HTTParty/ClassMethods#basic_auth-instance_method"
          target="_blank"
        >basic_auth documentation</a>
        for implementing using HTTParty.
      </p>
      <pre>class Foo
  include HTTParty
  basic_auth 'tokenKey', 'tokenSecret'
end
</pre>

      <h4>Axios</h4>
      <p>
        See
        <a
          href="https://axios-http.com/docs/req_config"
          target="_blank"
        >HTTP Basic auth documentation</a>
        for implementing using Axios.
      </p>
      <pre>{
  auth: {
    username: 'tokenKey',
    password: 'tokenSecret'
  }
}
</pre>

      <h3>Base URL</h3>
      <p>All endpoints start with the same base URL:</p>
      <pre>https://api.picked.ai</pre>

      <h3>Candidates</h3>
      <pre>GET /assessment/candidates</pre>
      <p>Fetch candidates and their assessments.</p>

      <h4>Parameters</h4>
      <GuideCardAttributesTable
        :attributes="candidatesAttributes"
      />
      <pre>{{ exampleCandidates }}</pre>
    </div>
  </BaseCard>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Team from '@components/Icons/Team'
import GuideCardAttributesTable from '@components/Settings/Webhooks/GuideCardAttributesTable'

const exampleCandidates = {
  'data': [
    {
      'id': 'd0157921-773c-3ca3-8410-e4aafb2423d1',
      'email': 'candidate+constantin_kertzmann@picked.ai',
      'sendingEmailFailed': false,
      'firstName': 'Constantin',
      'surname': 'Kertzmann',
      'createdAt': 1622183638,
      'sentExams': [
        {
          'slug': 'numerical-reasoning',
          'name': 'Numerical Reasoning Standard',
          'sentAt': 1622199838,
          'completedAt': 1622199838,
          'started': true
        }
      ],
      'attempts': [
        {
          'examSlug': 'numerical-reasoning',
          'examName': 'Numerical Reasoning Standard',
          'scorePercentage': 82,
          'speedCompleted': 632,
          'questionsAnswered': 16,
          'questionsCorrect': 13,
          'questionsIncorrect': 3,
          'questionsTotal': 16,
          'result': null,
          'scores': null,
          'summary': null,
          'responses': null,
          'traits': null,
          'leaveAssessmentWindowCount': null,
          'hasExitedFullScreen': null,
          'webcam': null,
          'ipAddressChanged': null,
          'unlocked': true,
          'createdAt': 1622199838
        }
      ]
    }
  ],
  'links': {
    'first': 'https://api.picked.ai/assessment/candidates?page=1',
    'last': 'https://api.picked.ai/assessment/candidates?page=1',
    'prev': null,
    'next': null
  },
  'meta': {
    'current_page': 1,
    'from': 1,
    'last_page': 1,
    'path': 'https://api.picked.ai/assessment/candidates',
    'per_page': 50,
    'to': 5,
    'total': 5,
    'sort_by': 'created_at',
    'sort_order': 'desc'
  }
}

const candidatesAttributes = [
  {
    attribute: 'per_page',
    type: 'integer',
    description: 'Number per page, defaults to 50, max is 100'
  },
  {
    attribute: 'sort_order',
    type: 'string',
    description: 'Order of the candidates, desc is the default, asc is an option'
  },
  {
    attribute: 'sort_by',
    type: 'string',
    description: 'Which field to sort by, created_at is the default'
  }
]

export default {
  components: {
    Icon,
    Team,
    GuideCardAttributesTable
  },

  data() {
    return {
      candidatesAttributes,
      exampleCandidates
    }
  }
}
</script>
