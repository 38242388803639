<template>
  <div>
    <SettingsNavigation />

    <div
      class="max-w-screen-xl mx-auto sm:grid sm:grid-cols-2 sm:gap-x-6 px-6"
    >
      <AboutCard />
      <TokensCard />
    </div>

    <div class="max-w-screen-xl mx-auto px-6 mt-6">
      <GuideCard />
    </div>
  </div>
</template>

<script>
import AboutCard from '@components/Settings/API/AboutCard'
import GuideCard from '@components/Settings/API/GuideCard'
import SettingsNavigation from '@components/Settings/SettingsNavigation'
import TokensCard from '@components/Settings/API/TokensCard'

export default {
  components: {
    AboutCard,
    GuideCard,
    SettingsNavigation,
    TokensCard
  }
}
</script>
