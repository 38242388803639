<template>
  <BaseCard>
    <header class="flex items-center justify-between">
      <div class="flex items-center">
        <span
          class="flex flex-col justify-center items-center h-10 w-10 rounded-full mr-4 bg-secondary text-white"
        >
          <Icon
            width="24px"
            height="24px"
            view-box="0 0 20 20"
          >
            <Terminal />
          </Icon>
        </span>
        <h2 class="text-lg">
          API
        </h2>
      </div>
    </header>
    <div class="prose mt-4">
      <p>
        Our API lets you interact with our services via your own application.
      </p>
      <p>
        We provide endpoints for the following services:
      </p>
      <ul>
        <li>Candidate data, including their attempts</li>
      </ul>
      <p>More endpoints will be added in the future.</p>
    </div>
  </Basecard>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Terminal from '@components/Icons/Terminal'

export default {
  components: {
    Icon,
    Terminal
  }
}
</script>
