import { pickedApiRequest } from './request'

export default {
  /**
   * Fetch current tokens
   *
   * @param {string} organisationId
   * @return {Array}
   */
  index (organisationId) {
    return pickedApiRequest.get('assessment/organisations/' + organisationId + '/tokens')
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Create new token
   *
   * @param {string} organisationId
   * @return {Array}
   */
  create (organisationId) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/tokens')
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Delete a token
   *
   * @param {string} organisationId
   * @param {string} tokenKey
   * @return {Array}
   */
  delete (organisationId, tokenKey) {
    return pickedApiRequest.delete('assessment/organisations/' + organisationId + '/tokens/' + tokenKey)
      .then(response => {
        return response.data.data
      })
  }
}
