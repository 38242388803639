<template>
  <BaseCard>
    <header class="flex items-center justify-between">
      <div class="flex items-center">
        <span
          class="flex flex-col justify-center items-center h-10 w-10 rounded-full mr-4 bg-secondary text-white"
        >
          <Icon
            width="24px"
            height="24px"
            view-box="0 0 20 20"
          >
            <ClipboardCheck />
          </Icon>
        </span>
        <h2 class="text-lg">
          API tokens
        </h2>
      </div>

      <CreateTokenButton
        @tokenCreated="getTokens()"
      />
    </header>

    <section v-if="Array.isArray(tokens)">
      <table
        v-if="tokens.length > 0"
        class="w-full mt-4"
      >
        <thead class="border-b border-gray-200 text-sm">
          <tr>
            <th class="py-2 text-left">
              Token key
            </th>
            <th class="py-2 text-left">
              Last used
            </th>
            <th class="py-2 text-left">
              Options
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="token in tokens"
            :key="token.key"
            class="text-gray-700"
          >
            <td class="py-2">
              {{ token.key }}
            </td>
            <td v-if="token.lastUsedOn">
              {{ token.lastUsedOn }}
            </td>
            <td v-else-if="token.lastUsedOn === null">
              Never used
            </td>
            <td>
              <DeleteTokenButton
                :token-key="token.key"
                @tokenDeleted="tokenDeleted($event)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <Notice
        v-else-if="tokens.length === 0"
        class="p-4 mt-4"
      >
        You currently don’t have any tokens. Create one to get started
      </Notice>
    </section>
    <ErrorsBlock
      v-else-if="hasError"
      class="mt-6"
    >
      Sorry, an error occurred
    </ErrorsBlock>
    <div v-else-if="tokens === null">
      <Loader class="mt-4" />
    </div>
    <ErrorsBlock
      v-else
      class="mt-6"
    >
      Sorry, an unexpected error occurred
    </ErrorsBlock>
  </Basecard>
</template>

<script>
import ClipboardCheck from '@components/Icons/ClipboardCheck'
import CreateTokenButton from '@components/Settings/API/CreateTokenButton'
import DeleteTokenButton from '@components/Settings/API/DeleteTokenButton'
import ErrorsBlock from '@components/ErrorsBlock'
import Icon from '@components/Icons/Icon'
import Loader from '@components/Loader'
import Notice from '@components/Notice'

import tokensApi from '@api/tokens'
import { mapGetters } from 'vuex'

export default {
  components: {
    ClipboardCheck,
    CreateTokenButton,
    DeleteTokenButton,
    ErrorsBlock,
    Icon,
    Loader,
    Notice
  },

  data() {
    return {
      hasError: false,
      tokens: null
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId'
    })
  },

  created() {
    this.getTokens()
  },

  methods: {
    getTokens() {
      this.tokens = null

      return tokensApi.index(this.organisationId)
        .then(data => {
          this.tokens = data

          this.$emit('tokenCreated')
        })
        .catch(error => {
          console.error(error)
          this.hasError = true
        })
    },

    /**
     * Post-delete response
     */
    tokenDeleted(tokens) {
      this.tokens = tokens
    }
  }
}
</script>
