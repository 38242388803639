<template>
  <div>
    <BaseButton @click="modalOpen = true">
      <template slot="iconLeft">
        <Icon
          view-box="0 0 24 24"
          class="w-5 h-5"
        >
          <Plus />
        </Icon>
      </template>
      Create token
    </BaseButton>

    <Modal
      :open="modalOpen"
      @close="modalOpen = false"
    >
      <div class="bg-white border-b-2">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="text-lg font-medium">
            Create token
          </h3>
          <button
            class="text-gray-500 focus:outline-none"
            @click="close"
          >
            <Icon
              view-box="0 0 24 24"
              class="w-5 h-5 transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>

      <CreateTokenForm
        @tokenCreated="$emit('tokenCreated')"
        @close="modalOpen = false"
      />
    </Modal>
  </div>
</template>

<script>
import Modal from '@components/Modal'
import CreateTokenForm from '@components/Settings/API/CreateTokenForm'
import Icon from '@components/Icons/Icon'
import Plus from '@components/Icons/Plus'

export default {
  components: {
    Modal,
    CreateTokenForm,
    Icon,
    Plus
  },

  data() {
    return {
      modalOpen: false
    }
  },

  methods: {
    close() {
      this.modalOpen = false
      this.$emit('close')
    }
  }
}
</script>
