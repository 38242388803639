<template>
  <div class="px-6 pt-6 pb-8 bg-gray-100">
    <section v-if="token">
      <dl class="flex flex-wrap text-sm">
        <dt class="w-1/3 p-1 text-gray-500">
          Token key (username)
        </dt>
        <dd class="w-2/3 p-1">
          <span class="font-mono font-bold">{{ token.key }}</span>
        </dd>
        <dt class="w-1/3 p-1 text-gray-500">
          Token secret (password)
        </dt>
        <dd class="w-2/3 p-1">
          <span class="font-mono font-bold">{{ token.secret }}</span>
        </dd>
      </dl>
      <Notice
        class="mt-2 p-4"
        variant="warning"
      >
        Your secret won’t be shown again
      </Notice>
    </section>
    <div v-else>
      <Notice variant="info">
        When you create a token you’ll be shown the key and secret. Make a note of the secret as it won’t be shown again
      </Notice>
      <section class="mx-auto text-right">
        <BaseButton
          class="mt-8"
          :loading="isLoading"
          @click="createToken()"
        >
          Create token
        </BaseButton>
        <ErrorsBlock
          v-if="hasError"
          class="mt-2"
        >
          Sorry, an error occured – please try again
        </ErrorsBlock>
      </section>
    </div>
  </div>
</template>

<script>
import ErrorsBlock from '@components/ErrorsBlock'
import Notice from '@components/Notice'

import tokensApi from '@api/tokens'
import { mapGetters } from 'vuex'

export default {
  components: {
    ErrorsBlock,
    Notice
  },

  data() {
    return {
      hasError: false,
      isLoading: false,
      token: null
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId'
    })
  },

  methods: {
    /**
     * Start process for creating a token
     */
    createToken() {
      if (this.isLoading) {
        return
      }

      this.hasError = false
      this.isLoading = true

      return tokensApi.create(this.organisationId)
        .then(data => {
          this.isLoading = false
          this.token = data

          this.$emit('tokenCreated')
        })
        .catch(error => {
          console.error(error)
          this.hasError = true
          this.isLoading = false
        })
    }
  }
}
</script>
