<template>
  <div>
    <button
      class="hover:text-red-400 duration-150"
      @click="showModal = true"
    >
      <span class="hidden sm:inline-block sr-only">Delete</span>

      <Icon
        icon="Bin"
        view-box="0 0 24 24"
        class="w-6 h-6 ml-1 fill-none"
      >
        <Bin />
      </Icon>
    </button>

    <Modal
      :open="showModal"
      @close="attemptClose()"
    >
      <div class="border-b-2 bg-white">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="font-medium text-lg">
            Delete {{ tokenKey }}
          </h3>
          <button
            v-if="!isProcessing"
            class="text-gray-500 focus:outline-none"
            @click="showModal = false"
          >
            <Icon
              width="16px"
              height="16px"
              view-box="0 0 14 14"
              class="transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>
      <div
        v-if="!isProcessing"
        class="m-6"
      >
        <p>Are you sure you want to delete this token?</p>

        <Notice
          class="my-6"
          variant="info"
        >
          When you remove a token, all requests that use this token will stop working
        </Notice>

        <div class="flex justify-between my-6">
          <BaseButton
            variant="gray"
            @click="showModal = false"
          >
            No, cancel
          </BaseButton>
          <BaseButton
            variant="danger"
            @click="deleteToken()"
          >
            Yes, delete
          </BaseButton>
        </div>
      </div>
      <div
        v-else
        class="my-16"
      >
        <Loader />
      </div>
    </Modal>
  </div>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Bin from '@components/Icons/Bin'
import Loader from '@components/Loader'
import Modal from '@components/Modal'
import Notice from '@components/Notice'
import Plus from '@components/Icons/Plus'

import tokensApi from '@api/tokens'
import { mapGetters } from 'vuex'

export default {
  components: {
    Icon,
    Loader,
    Modal,
    Notice,
    Plus,
    Bin
  },

  props: {
    tokenKey: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isProcessing: false,
      showModal: false
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId'
    })
  },

  methods: {
    /**
     * Attempt to close the modal
     */
    attemptClose() {
      if (this.isProcessing) {
        return // Cannot close while processing
      }
      this.showModal = false
    },

    /**
     * Carry out deletion
     */
    deleteToken() {
      this.isProcessing = true

      return tokensApi
        .delete(this.organisationId, this.tokenKey)
        .then(data => {
          this.showModal = false
          this.$emit('tokenDeleted', data)
        })
    }
  }
}
</script>
