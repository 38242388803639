<template>
  <BaseContainer>
    <APISettings />
  </BaseContainer>
</template>

<script>
import APISettings from '@components/Settings/APISettings'

export default {
  components: {
    APISettings
  },

  page() {
    return {
      title: 'API'
    }
  }
}
</script>
